<template>
  <el-dialog
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    :visible="dialogVisible"
    class="ex-confirm-gdpr"
    width="60%"
  >
    <div class="ex-confirm-gdpr__message">
      <p>{{currentGDPR.text}}</p>
      <br>
      <p>Read more about the outlined updates here:</p>
      <p>
        <a :href="`${fileStorageUrl}/cdn/policy/privacy`" target="_blank">
          <strong>Privacy Policy</strong>
        </a>
        <span>, </span>
      </p>
      <p>
        <a :href="`${fileStorageUrl}/cdn/policy/cookie`" target="_blank">
          <strong>Cookie Policy</strong>
        </a>
        <span>.</span>
      </p>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button :disabled="isLoading" @click="cancel">Reject</el-button>
      <el-button type="primary" :loading="isLoading" @click="confirm">Accept Changes</el-button>
    </span>
  </el-dialog>
</template>

<script>
// api
import profileApi from '@/api/userService/profile.api';

// lib
import {mapMutations, mapState} from 'vuex';

// constant
import {UPDATE_USER_GDPR_POLICY_VERSION} from '@/constants/events/currentUser/actions.type';

// utils
import {socketRequest} from '@/utils/socket';
import {FILE_STORAGE_URL} from '@/utils/appsURLs';

export default {
  name: 'ExConfirmGdpr',
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    ...mapState('currentUser', {userId: 'id', userProfile: 'profile'}),
    ...mapState('content', {currentGDPR: 'GDPR'}),
    dialogVisible() {
      return this.userProfile.policyVersion !== undefined
        && this.userProfile.policyVersion !== this.currentGDPR.version
        && this.currentGDPR.version !== '';
    },
    fileStorageUrl() {
      return `${FILE_STORAGE_URL}`;
    },
  },
  methods: {
    ...mapMutations('currentUser', ['setGDPRVersion']),
    confirm() {
      this.isLoading = true;
      profileApi.updateGdprPolicyVersion(
        UPDATE_USER_GDPR_POLICY_VERSION,
        {userId: this.userId, policyVersion: this.currentGDPR.version},
      );
      socketRequest.call(this, UPDATE_USER_GDPR_POLICY_VERSION,
        () => {
          this.setGDPRVersion(this.currentGDPR.version);
          return 'Successfully saved GDPR settings';
        },
        () => {}, // 202, default
        () => {
          this.isLoading = false;
        },
      );
    },
    cancel() {
      this.EventBus.$emit('logout');
    },
  },
};
</script>

<style scoped lang="scss">
.ex-confirm-gdpr {
  &__message {
    word-break: break-word;
    font-size: 20px;
  }
}
</style>
